import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import Shows from "./Shows/Shows"
import SEO from "~/components/Seo/Seo"
import { Container } from "~/components/ui"
import { Episode, Show } from "~/models/Show"
import { Video } from "~/models/Video"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"

import css from "./HomeShows.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: HomeShowsQuery
}

function HomeShows({ data, location }: PropTypes) {
  const intl = useIntl()
  return (
    <div className={css.root}>
      <Container>
        <SEO
          title={intl.formatMessage({ id: "show/text:home-title" })}
          location={location}
        />
        <Shows
          publishedShows={data.publishedShows}
          teaserShows={data.teaserShows}
          episodes={data.episodes}
          mobileHomeVideo={data.mobileHomeVideo}
          desktopHomeVideo={data.desktopHomeVideo}
        />
      </Container>
    </div>
  )
  //
}

export interface HomeShowsQuery {
  episodes: {
    nodes: Episode[]
  }
  publishedShows: {
    nodes: Show[]
  }
  teaserShows?: {
    nodes: Show[]
  }
  mobileHomeVideo: Video
  desktopHomeVideo: Video
}
export const query = graphql`
  query HomeShowsQuery(
    $language: String
    $mobileHomeVideoId: String
    $desktopHomeVideoId: String
  ) {
    episodes: allEpisode(
      sort: { fields: published_date, order: DESC }
      filter: { language: { eq: $language } }
      limit: 10
    ) {
      nodes {
        ...Episode
      }
    }
    publishedShows: allShow(
      sort: { fields: position, order: ASC }
      filter: { is_teaser: { eq: false }, language: { eq: $language } }
    ) {
      nodes {
        ...Show
      }
    }
    teaserShows: allShow(
      sort: { fields: position, order: ASC }
      filter: { is_teaser: { eq: true }, language: { eq: $language } }
    ) {
      nodes {
        ...Show
      }
    }
    mobileHomeVideo: video(id: { eq: $mobileHomeVideoId }) {
      hostedVideoUrl: hosted_video_url
      videoPreviewThumbnail: video_preview_thumbnail
    }
    desktopHomeVideo: video(id: { eq: $desktopHomeVideoId }) {
      hostedVideoUrl: hosted_video_url
      videoPreviewThumbnail: video_preview_thumbnail
    }
  }
`

export default withTemplate(HomeShows)
